import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { tenantApi } from "./services/tenant";
import { DashboardAPI, OpenAiApi } from "./services";

const ReduxStore = configureStore({
  reducer: {
    [tenantApi?.reducerPath]: tenantApi?.reducer,
    [OpenAiApi?.reducerPath]: OpenAiApi?.reducer,
    [DashboardAPI?.reducerPath]: DashboardAPI?.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(tenantApi?.middleware).concat(OpenAiApi?.middleware).concat(DashboardAPI?.middleware),
});

export const AppRedux = ({ children }) => {
  return <Provider store={ReduxStore}>{children}</Provider>;
};
